export const CREDIT_LIMITS_REDUX_CONSTANTS = {
  CREDIT_LIMITS_LIST_ACTION: 'CREDIT_LIMITS_LIST_ACTION',
  SELECTED_CREDIT_LIMIT_DATA: 'SELECTED_CREDIT_LIMIT_DATA',
  RESET_CREDIT_LIMIT_LIST_DATA: 'RESET_CREDIT_LIMIT_LIST_DATA',
  RESET_VIEW_CREDIT_LIMIT_DATA: 'RESET_VIEW_CREDIT_LIMIT_DATA',
  VIEW_CREDIT_LIMIT_ACTIVE_TAB_INDEX: 'VIEW_CREDIT_LIMIT_ACTIVE_TAB_INDEX',
};

export const CREDIT_LIMITS_COLUMN_LIST_REDUX_CONSTANTS = {
  CREDIT_LIMITS_COLUMN_LIST: 'CREDIT_LIMITS_COLUMN_LIST',
  CREDIT_LIMITS_DEFAULT_COLUMN_LIST: 'CREDIT_LIMITS_DEFAULT_COLUMN_LIST',
  UPDATE_CREDIT_LIMITS_COLUMN_LIST: 'UPDATE_CREDIT_LIMITS_COLUMN_LIST',
};

export const CREDIT_LIMITS_FILTER_LIST_REDUX_CONSTANTS = {
  CREDIT_LIMITS_FILTER_LIST: 'CREDIT_LIMITS_FILTER_LIST',
};

export const CREDIT_LIMITS_APPLICATION_REDUX_CONSTANTS = {
  CREDIT_LIMIT_APPLICATION_LIST_REQUEST: 'CREDIT_LIMIT_APPLICATION_LIST_REQUEST',
  CREDIT_LIMIT_APPLICATION_LIST_SUCCESS: 'CREDIT_LIMIT_APPLICATION_LIST_SUCCESS',
  CREDIT_LIMIT_APPLICATION_LIST_FAIL: 'CREDIT_LIMIT_APPLICATION_LIST_FAIL',
  CREDIT_LIMITS_APPLICATION_COLUMN_LIST: 'CREDIT_LIMITS_APPLICATION_COLUMN_LIST',
  CREDIT_LIMITS_APPLICATION_DEFAULT_COLUMN_LIST: 'CREDIT_LIMITS_APPLICATION_DEFAULT_COLUMN_LIST',
  UPDATE_CREDIT_LIMITS_APPLICATION_COLUMN_LIST: 'UPDATE_CREDIT_LIMITS_APPLICATION_COLUMN_LIST',
};

export const CREDIT_LIMITS_TASKS_REDUX_CONSTANTS = {
  CREDIT_LIMITS_TASK_LIST: 'CREDIT_LIMITS_TASK_LIST',
  CREDIT_LIMITS_TASK_COLUMN_LIST: 'CREDIT_LIMITS_TASK_COLUMN_LIST',
  CREDIT_LIMITS_TASK_DEFAULT_COLUMN_LIST: 'CREDIT_LIMITS_TASK_DEFAULT_COLUMN_LIST',
  UPDATE_CREDIT_LIMITS_TASK_COLUMN_LIST: 'UPDATE_CREDIT_LIMITS_TASK_COLUMN_LIST',
};

export const CREDIT_LIMITS_DOCUMENTS_REDUX_CONSTANTS = {
  REQUEST_CREDIT_LIMITS_DOCUMENTS_LIST: 'REQUEST_CREDIT_LIMITS_DOCUMENTS_LIST',
  CREDIT_LIMITS_DOCUMENTS_LIST_SUCCESS: 'CREDIT_LIMITS_DOCUMENTS_LIST_SUCCESS',
  CREDIT_LIMITS_DOCUMENTS_COLUMN_LIST: 'CREDIT_LIMITS_DOCUMENTS_COLUMN_LIST',
  CREDIT_LIMITS_DOCUMENTS_DEFAULT_COLUMN_LIST: 'CREDIT_LIMITS_DOCUMENTS_DEFAULT_COLUMN_LIST',
  UPDATE_CREDIT_LIMITS_DOCUMENTS_COLUMN_LIST: 'UPDATE_CREDIT_LIMITS_DOCUMENTS_COLUMN_LIST',
  CREDIT_LIMITS_DOCUMENT_TYPE_LIST: 'CREDIT_LIMITS_DOCUMENT_TYPE_LIST',
  UPLOAD_DOCUMENT_CREDIT_LIMITS: 'UPLOAD_DOCUMENT_CREDIT_LIMITS',
};

export const CREDIT_LIMITS_NOTES_REDUX_CONSTANTS = {
  REQUEST_CREDIT_LIMITS_NOTES_LIST: 'REQUEST_CREDIT_LIMITS_NOTES_LIST',
  CREDIT_LIMITS_NOTES_LIST_SUCCESS: 'CREDIT_LIMITS_NOTES_LIST_SUCCESS',
};

export const CREDIT_LIMITS_STAKE_HOLDER_REDUX_CONSTANTS = {
  CREDIT_LIMIT_STAKE_HOLDER_LIST_SUCCESS: 'CREDIT_LIMIT_STAKE_HOLDER_LIST_SUCCESS',
  CREDIT_LIMITS_STAKE_HOLDER_COLUMN_LIST: 'CREDIT_LIMITS_STAKE_HOLDER_COLUMN_LIST',
  CREDIT_LIMITS_STAKE_HOLDER_DEFAULT_COLUMN_LIST: 'CREDIT_LIMITS_STAKE_HOLDER_DEFAULT_COLUMN_LIST',
  UPDATE_CREDIT_LIMITS_STAKE_HOLDER_COLUMN_LIST: 'UPDATE_CREDIT_LIMITS_STAKE_HOLDER_COLUMN_LIST',
};
