export const REPORTS_REDUX_CONSTANTS = {
  GET_REPORT_LIST_SUCCESS: 'GET_REPORT_LIST_SUCCESS',
  RESET_REPORT_LIST_DATA: 'RESET_REPORT_LIST_DATA',
  INITIALIZE_FILTERS: 'INITIALIZE_FILTERS',

  GET_REPORT_COLUMN_LIST: 'GET_REPORT_COLUMN_LIST',
  GET_REPORT_DEFAULT_COLUMN_LIST: 'GET_REPORT_DEFAULT_COLUMN_LIST',
  UPDATE_REPORT_COLUMN_LIST: 'UPDATE_REPORT_COLUMN_LIST',

  GET_DROPDOWN_CLIENT_LIST: 'GET_DROPDOWN_CLIENT_LIST',
  GET_DROPDOWN_LIST_BY_SEARCH: 'GET_DROPDOWN_LIST_BY_SEARCH',
  GET_ALERT_FILTER_DROPDOWN_DATA: 'GET_ALERT_FILTER_DROPDOWN_DATA',

  UPDATE_REPORT_FILTER_FIELDS: 'UPDATE_REPORT_FILTER_FIELDS',
  APPLY_REPORT_FILTER_ACTION: 'APPLY_REPORT_FILTER_ACTION',
  CLOSE_REPORT_FILTER_ACTION: 'CLOSE_REPORT_FILTER_ACTION',

  RESET_REPORT_FILTER: 'RESET_REPORT_FILTER',
};
