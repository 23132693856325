export const USER_MANAGEMENT_REDUX_CONSTANTS = {
  USER_MANAGEMENT_LIST_USER_ACTION: 'USER_MANAGEMENT_LIST_USER_ACTION',
  FETCH_USER_MANAGEMENT_LIST_REQUEST: 'FETCH_USER_MANAGEMENT_LIST_REQUEST',
  FETCH_USER_MANAGEMENT_LIST_SUCCESS: 'FETCH_USER_MANAGEMENT_LIST_SUCCESS',
  FETCH_USER_MANAGEMENT_LIST_FAILURE: 'FETCH_USER_MANAGEMENT_LIST_FAILURE',
  RESET_USERLIST_PAGINATION_DATA: 'RESET_USERLIST_PAGINATION_DATA',
  RESET_USER_LIST_DATA: 'RESET_USER_LIST_DATA',
  PRIVILEGES: {
    GET_ALL_USER_PRIVILEGES: 'GET_ALL_USER_PRIVILEGES',
    UPDATE_USER_PRIVILEGE: 'UPDATE_USER_PRIVILEGE',
  },
};

export const USER_MANAGEMENT_COLUMN_LIST_REDUX_CONSTANTS = {
  USER_MANAGEMENT_COLUMN_LIST_ACTION: 'USER_MANAGEMENT_COLUMN_LIST_ACTION',
  USER_MANAGEMENT_DEFAULT_COLUMN_LIST_ACTION: 'USER_MANAGEMENT_DEFAULT_COLUMN_LIST_ACTION',
  UPDATE_USER_MANAGEMENT_COLUMN_LIST_ACTION: 'UPDATE_USER_MANAGEMENT_COLUMN_LIST_ACTION',
};

export const USER_MANAGEMENT_CLIENT_LIST_REDUX_CONSTANTS = {
  USER_MANAGEMENT_CLIENT_LIST_ACTION: 'USER_MANAGEMENT_CLIENT_LIST_ACTION',
};

export const USER_MANAGEMENT_CRUD_REDUX_CONSTANTS = {
  USER_MANAGEMENT_GET_USER_ACTION: 'USER_MANAGEMENT_GET_USER_ACTION',
  USER_MANAGEMENT_UPDATE_USER_ACTION: 'USER_MANAGEMENT_UPDATE_USER_ACTION',
  USER_MANAGEMENT_CHANGE_MANAGE_ACCESS_USER_ACTION:
    'USER_MANAGEMENT_CHANGE_MANAGE_ACCESS_USER_ACTION',
  USER_MANAGEMENT_UPDATE_DUPLICATE_MODULE_ACCESS: 'USER_MANAGEMENT_UPDATE_DUPLICATE_MODULE_ACCESS',
  USER_MANAGEMENT_UNDO_SELECTED_USER_DATA_ON_CLOSE:
    'USER_MANAGEMENT_UNDO_SELECTED_USER_DATA_ON_CLOSE',
  RESET_VIEW_USER_DATA: 'RESET_VIEW_USER_DATA',
};

export const ORGANISATION_MODULE_REDUX_CONSTANTS = {
  GET_ORGANISATION_MODULE_REDUX_ACTION: 'GET_ORGANISATION_MODULE_REDUX_ACTION',
};
