export const EDIT_PROFILE_CONSTANT = {
  GET_LOGGED_USER_DETAILS: 'GET_LOGGED_USER_DETAILS',
  USER_EDIT_PROFILE_DATA_CHANGE: 'USER_EDIT_PROFILE_DATA_CHANGE',
  UPDATE_USER_PROFILE_PICTURE: 'UPDATE_USER_PROFILE_PICTURE',
};
export const HEADER_NOTIFICATION_REDUX_CONSTANTS = {
  GET_HEADER_NOTIFICATION: 'GET_HEADER_NOTIFICATION',
  TASK_DELETED_READ: 'TASK_DELETED_READ',
  TASK_ASSIGNED: 'TASK_ASSIGNED',
  TASK_UPDATED: 'TASK_UPDATED',
  DUE_TASK: 'DUE_TASK',
  APPLICATION_APPROVED: 'APPLICATION_APPROVED',
  APPLICATION_DECLINED: 'APPLICATION_DECLINED',
  APPLICATION_GENERATED: 'APPLICATION_GENERATED',
  CLAIM_ADDED: 'CLAIM_ADDED',
  OFF_NOTIFIRE: 'OFF_NOTIFIRE',
  MARKED_ALL_AS_READ: 'MARKED_ALL_AS_READ',
};

export const HEADER_GLOBAL_SEARCH_REDUX_CONSTANTS = {
  GET_SEARCH_RESULT_LIST: 'GET_SEARCH_RESULT_LIST',
  CLEAR_SEARCHED_DATA_LIST: 'CLEAR_SEARCHED_DATA_LIST',
};
